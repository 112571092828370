import { useCallback } from "react";
import { useState, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal
} from "react-bootstrap";
import SectionCompForIJHA from "./SectionCompForIJHA";
import FormProgressBarforDynamic from "../../components/FormProgressBar/FormProgressBarforDynamic";
import _, { isEmpty } from "lodash";
import "../../index.scss";
import {
  ijhaFormSubmit,
  ijhaInCompleteFormSubmit,
  deleteImageFromBlob,
} from "../../api/CreateiJHAforIJHADynamic.api";
import Loading from "../../components/Loading/Loading";
import { EMP_ID } from "../../utils/Variable";
import { v4 as uuidv4 } from "uuid";
import ic_complete_ppe from "../../assets/Icons/ic_complete_ppe.png";
import {
  getCurrentDataTime,
  getTotalTime,
  getFileTypeImgDoc,
  removeTags,
} from "../../utils/Helper";
import { getWeatherApi } from "../../api/Common.api";
import SummaryForIJHA from "./SummaryForIJHA";
import ModalMapTramaCenterforIJhaDynamic from "../../components/Modal/ModalMapTramaCenterforIJhaDynamic";
import ModalMapCustomforIjhaDynamic from "../../components/Modal/ModalMapCustomforIjhaDynamic";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
export default function SelectedSectionForm({
  setLoc,
  getLoc,
  getLocationavailable,
  selectedSectionData,
  form_template_p,
  getNewReport,
  getAllCrewMemData,
  FormData,
  setIsAddCrew,
  setCrewUpdate,
  setForm_template_API,
  _setProceed,
  _getProceed,
}) {
  const navigate = useNavigate();
  const [getIjhaform, setIjhaform] = useState(form_template_p);

  const [form_template, setForm_template] = useState(getIjhaform.form_template);
  const [getbutton_control, setbutton_control] = useState(true);
  const [getProceed, setProceed] = useState(_getProceed);
  const [getProceedOld, setProceedOld] = useState("");
  const [getpbtn, setpbtn] = useState(false);
  // const [Summarypage, setSummarypage] = useState(false);
  const [getError2, setError2] = useState("");
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    setError,
    watch,
  } = useForm();
  const [getSiteLandLord, setSiteLandLord] = useState(false);
  const [getVerifiedValue, setVerifiedValue] = useState(false);
  const [getLoading, setLoading] = useState(false);
  const [getLoading1, setLoading1] = useState(false);
  const [getCrewLeadData, setCrewLeadData] = useState(getAllCrewMemData);
  const [getLatitude, setLatitude] = useState(0);
  const [getLongitude, setLongitude] = useState(0);
  const [getImgMetaData, setImgMetaData] = useState();
  const [getSubmitMsg, setSubmitMsg] = useState("");
  const [getReportRequired, setReportRequired] = useState(getNewReport);
  const [showScroll, setShowScroll] = useState(false);
  const [getSummarybutton, setSummarybutton] = useState(false);
  const [validationforfields, setvalidationforfields] = useState(false);
  const [validationofsubmit, setvalidationofsubmit] = useState(false);
  const [getChecksumValue, setChecksumValue] = useState();
  const [getWeatherHours, setWeatherHours] = useState([]);
  const [getCustomername1, setCustomername1] = useState(false);
  const [typeofstructure, settypeofstructure] = useState(false);
  const [getUpdateWeather, setUpdateWeather] = useState(false);
  const [getProceed1, setProceed1] = useState(0);
  const [getErrMsg, setErrMsg] = useState("");
  const [getAddress, setAddress] = useState("");
  const [getCrewLead, setCrewLead] = useState(0);
  const [showMdl, setShowMdl] = useState(false);
  const [getCrewfill, setCrewfill] = useState(false);
  const [getCrewfillComplete, setCrewfillComplete] = useState(false);
  const [getIsCompleted, setIsCompleted] = useState([]);
  // const [getDelCrew, setDelCrew] = useState(false);
  const [showTraumaMdl, setShowTraumaMdl] = useState(false);
  const [getUnit, setUnit] = useState("");
  const [getReportID, setReportId] = useState();
  const [getSubmittedParentId, setSubmittedParentId] = useState();
  const [radioValue, setRadioValue] = useState("imperial");
  const [getCrewMenProceed, setCrewMenProceed] = useState(false);
  const [getFormData, setFormData] = useState(FormData);
  const [getClimber, setClimber] = useState(false);
  const [getClimberList, setClimberList] = useState([]);
  const [getSubmitStatusErr, setSubmitStatusErr] = useState("");

  let NewError = "";
  const [getDataMdl, setDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [directions, setDirections] = useState({});
  const [getCertifyData, setCertifyData] = useState({});
  const [getCertifySection, setCertifySection] = useState({});
  const [disabledback, setdisabledback] = useState(false);
  useEffect(() => {
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform.form_template = form_template;
    setForm_template_API(_getIjhaform);
    _setProceed(getProceed);
  }, [form_template]);

  const changeFormTemplateData = (arr) => {
    onChangeFormTemplate({
      sectionId: arr[0],
      answer: arr[1],
      questionId: arr[2],
      required: arr[3],
      checkbox_value: arr[4],
      dropdown_answer: arr[5],
    });
  };

  // const getLocation = async () => {
  //   var _latlng;
  //   if (getLoc.lat === "" && getLoc.lng === "") {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(async (position) => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //         setUnit("imperial");
  //         _latlng = _.cloneDeep(getLoc);
  //         _latlng.lat = position.coords.latitude;
  //         _latlng.lng = position.coords.longitude;
  //         setLoc(_latlng);
  //         // console.log(position);
  //         // if (getIjhaform.reportId === undefined && getIjhaform.complete_status !== "InProgress") {
  //         //    await get911AddressInfoApi(setForm_template, form_template, _latlng, setLoading)
  //         // }
  //       });
  //     } else {
  //       setErrMsg("Geolocation is not supported by this browser.");
  //     }
  //   }
  // };

  useEffect(() => {
    start_datetime_filling();
  }, []);

  const Locationon = () => {
    alert("Please Turn Your Browser Location On111.");
    window.location.reload();
  };
  const start_datetime_filling = () => {
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["start_time_datetime"] = getCurrentDataTime();
    setIjhaform(_getIjhaform);
  };

  const modalMapHandler = (
    heading,
    getAddress,
    changeFormTemplateDataforSpecificSection,
    lat,
    lng,
    _key
  ) => {
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,

      getLatitude: Number(lat),
      getLongitude: Number(lng),
      direction: false,
      body: "",
      changeFormTemplateDataforSpecificSection:
        changeFormTemplateDataforSpecificSection,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    setDataMdl(dataMdl);
    setShowMdl(true);
  };
  const wheather_radio = [
    { name: "°F", value: "imperial" },
    { name: "°C", value: "metric" },
  ];

  const sendData = {
    latitude:
      getLatitude === 0
        ? getLocationavailable && getLocationavailable.lat !== undefined
          ? getLocationavailable.lat
          : getLoc.lat !== ""
            ? getLoc.lat
            : getIjhaform.latitude
        : getLatitude,
    longitude:
      getLongitude === 0
        ? getLocationavailable && getLocationavailable.lng !== undefined
          ? getLocationavailable.lng
          : getLoc.lng !== ""
            ? getLoc.lng
            : getIjhaform.longitude
        : getLongitude,
    unit: radioValue,
    loadingTrue: useCallback(() => setLoading(true), [setLoading]),
    loadingFalse: useCallback(() => setLoading(false), [setLoading]),
  };

  const modalMapHandler1 = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key,
    lat,
    lng
  ) => {
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      getCurrLatitude:
        getLatitude === 0
          ? getLocationavailable && getLocationavailable.lat !== undefined
            ? getLocationavailable.lat
            : getLoc.lat !== ""
              ? getLoc.lat
              : getIjhaform.latitude
          : getLatitude,
      getCurrLongitude:
        getLongitude === 0
          ? getLocationavailable && getLocationavailable.lng !== undefined
            ? getLocationavailable.lng
            : getLoc.lng !== ""
              ? getLoc.lng
              : getIjhaform.longitude
          : getLongitude,
      getLatitude:
        getLatitude === 0
          ? getLocationavailable && getLocationavailable.lat !== undefined
            ? getLocationavailable.lat
            : getLoc.lat !== ""
              ? getLoc.lat
              : getIjhaform.latitude
          : getLatitude,
      getLongitude:
        getLongitude === 0
          ? getLocationavailable && getLocationavailable.lng !== undefined
            ? getLocationavailable.lng
            : getLoc.lng !== ""
              ? getLoc.lng
              : getIjhaform.longitude
          : getLongitude,
      direction: false,
      fullAdd: _.split(
        removeTags(form_template[getProceed].questions[_key].answer),
        "\n"
      ),
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    setDataMdl(dataMdl);
    setShowTraumaMdl(true);
  };

  const modalMapDirectionHandler = (
    heading,
    getAddress,
    changeFormTemplateDataforSpecificSection,
    _key
  ) => {
    let dataMdl = {
      heading: heading[0],
      setAddress: setAddress,
      getLatitude:
        getLatitude === 0
          ? getLocationavailable && getLocationavailable.lat !== undefined
            ? getLocationavailable.lat
            : getLoc.lat !== ""
              ? getLoc.lat
              : getIjhaform.latitude
          : getLatitude,
      getLongitude:
        getLongitude === 0
          ? getLocationavailable && getLocationavailable.lng !== undefined
            ? getLocationavailable.lng
            : getLoc.lng !== ""
              ? getLoc.lng
              : getIjhaform.longitude
          : getLongitude,
      origin: heading[1],
      destination: heading[2],

      direction: true,
      body: "",
      changeFormTemplateDataforSpecificSection:
        changeFormTemplateDataforSpecificSection,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };
    setDataMdl(dataMdl);
    setShowMdl(true);
  };

  useEffect(() => {
    if (
      getIjhaform.reportId !== undefined &&
      getIjhaform.reportId !== "" &&
      getIjhaform.complete_status === "InProgress"
    ) {
      updatedFormTemp1();
      setReportId(getIjhaform.reportId);
    } else {
      updatedFormTemp1();
      setReportId(uuidv4());
    }
    if (
      getIjhaform.inProgressSection !== undefined &&
      getIjhaform.inProgressSection !== ""
    ) {
      if (getIjhaform.inProgressSection === form_template.length - 1) {
        setProceed1(15);
      }
    }
    if (!_getProceed) {
      setProceed(0);
    } else {
      setProceed(_getProceed);
    }
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    let _checkClimbMember = _getCrewLeadData.crewmemberdata.map(
      (i, k) => i.membertype
    );
    let _checkClimber = [_getCrewLeadData.membertype, ..._checkClimbMember];
    setClimberList(_checkClimber);
  }, []);

  useEffect(() => {
    if (
      getIjhaform.parent_reportId !== undefined &&
      getIjhaform.reportId !== "" &&
      getIjhaform.Report_state === "submittedReport"
    ) {
      setSubmittedParentId(getIjhaform.parent_reportId);
    } else {
      setSubmittedParentId(getReportID);
    }
  }, []);

  useEffect(() => {
    let _getIsCompleted = [];
    _getIsCompleted[0] = false;
    getCrewLeadData.crewmemberdata.map((obj, i) => {
      _getIsCompleted[i + 1] = false;
    });
    setIsCompleted(_getIsCompleted);
    //  }
    // }
    // }
    // getLocation();
  }, []);

  const weatherDetailsChange = () => {
    if (
      form_template.some(
        (section) =>
          section.sectionName === "Environmental & Health Hazards - Weather"
      )
    ) {
      const pageIndexofEnvironmental = form_template.findIndex(
        (item) =>
          item.sectionName === "Environmental & Health Hazards - Weather"
      );
      let _weatherDetails;
      if (form_template[pageIndexofEnvironmental].questions[0].weather_imperial === undefined) {
        _weatherDetails = _.cloneDeep(form_template[pageIndexofEnvironmental].questions[0].weather_imperial);
      } else {
        _weatherDetails = _.cloneDeep(form_template[pageIndexofEnvironmental].questions[0].weather_metric);
      }
      let unitFC = "";
      switch (radioValue) {
        case "imperial":
          unitFC = wheather_radio[0].name;
          break;
        case "metric":
          unitFC = wheather_radio[1].name;
          break;
        default:
      }
      _weatherDetails["results"][0]["cloudCover"] =
        _weatherDetails["results"][0]["cloudCover"] + "%";
      _weatherDetails["results"][0]["dewPoint"]["value"] =
        _weatherDetails["results"][0]["dewPoint"]["value"] + " " + unitFC;
      _weatherDetails["results"][0]["pressure"]["value"] =
        _weatherDetails["results"][0]["pressure"]["value"] +
        " " +
        _weatherDetails["results"][0]["pressure"]["unit"];
      _weatherDetails["results"][0]["realFeelTemperature"]["value"] =
        _weatherDetails["results"][0]["realFeelTemperature"]["value"] +
        " " +
        unitFC;
      _weatherDetails["results"][0]["relativeHumidity"] =
        _weatherDetails["results"][0]["relativeHumidity"] + "%";
      _weatherDetails["results"][0]["temperature"]["value"] =
        _weatherDetails["results"][0]["temperature"]["value"] + " " + unitFC;
      _weatherDetails["results"][0]["visibility"]["value"] =
        _weatherDetails["results"][0]["visibility"]["value"] +
        " " +
        _weatherDetails["results"][0]["visibility"]["unit"];
      _weatherDetails["results"][0]["wind"]["speed"]["value"] =
        _weatherDetails["results"][0]["wind"]["speed"]["value"] +
        " " +
        _weatherDetails["results"][0]["wind"]["speed"]["unit"];
      _weatherDetails["results"][0]["windGust"]["speed"]["value"] =
        _weatherDetails["results"][0]["windGust"]["speed"]["value"] +
        " " +
        _weatherDetails["results"][0]["windGust"]["speed"]["unit"];
      return _weatherDetails;
    } else {
    }
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const removeImgData = (e) => {
    let _form_template = _.cloneDeep(form_template);
    if (Array.isArray(_form_template)) {
      _form_template.map((section) => {
        if (section.questions) {
          section.questions = section.questions.map((s_obj) => {
            if (s_obj.questionId === e.questionId) {
              if (s_obj.multiple_picture.length > 0) {
                s_obj.multiple_picture = s_obj.multiple_picture.filter(
                  (k, i) => i !== e.idx && k
                );
              } else if (s_obj.picture !== undefined) {
                if (e.idx === 0) {
                  s_obj.picture = "";
                }
              }
            }
            return s_obj;
          });
        } else if (section.section && Array.isArray(section.section)) {
          section.section.map((sub_section) => {
            if (sub_section.questions) {
              sub_section.questions = sub_section.questions.map((s_obj) => {
                if (s_obj.questionId === e.questionId) {
                  if (s_obj.multiple_picture.length > 0) {
                    s_obj.multiple_picture = s_obj.multiple_picture.filter(
                      (k, i) => i !== e.idx && k
                    );
                  } else if (s_obj.picture !== undefined) {
                    if (e.idx === 0) {
                      s_obj.picture = "";
                    }
                  }
                }
                return s_obj;
              });
            }
            return sub_section;
          });
        }
        return section;
      });
    }
    setForm_template(_form_template);
    deleteImageFromBlob(e.ImageUrl);
  };

  window.addEventListener("scroll", checkScrollTop);
  useEffect(() => {
    scrollUp();
  }, [getProceed, getProceed1]);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const _selectedSections = useMemo(() => {
    const _selectedSectionId = selectedSectionData
      .filter((obj) => obj.checked_val && obj.sectionId)
      .map((obj) => obj.sectionId);

    return _selectedSectionId;
  }, [selectedSectionData]);

  const indexsWithEmployeeId = [];

  form_template.forEach((obj, i) => {
    if (!obj.section || !obj.section.length) {
      return;
    }
    const indexes = obj.section.reduce((acc, section, index) => {
      if ("employeeId" in section) {
        return [...acc, index];
      }
      return acc;
    }, []);
    if (indexes.length) {
      indexsWithEmployeeId.push(i);
    }
  });
  const formTempChange = () => {
    let _formTemp = _.cloneDeep(form_template);
    indexsWithEmployeeId.forEach((element) => {
      _formTemp[element].section[0]["employeeId"] = EMP_ID().replaceAll(
        '"',
        ""
      );
    });

    getCrewLeadData.crewmemberdata.map((obj, i) => {
      indexsWithEmployeeId.forEach((element) => {
        _formTemp[element].section[i + 1]["employeeId"] = obj.employeeId;
      });
    });
    return _formTemp;
  };

  const InprocessFormSubmit = () => {
    let dropdownstatus = getCrewLeadData.status;
    let crew_members_id = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.employeeId)
      : "";
    // let _getProceedCount = getformTemplateCount.includes(getProceed) ? getformTemplateCount[getformTemplateCount.length - 1] : getProceed;
    // uncommented below to active climber and nonclimber
    let membertype = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.membertype)
      : "";
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["form_template"] = formTempChange();
    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["parent_reportId"] =
      _getIjhaform.hasOwnProperty("Report_state") &&
        getIjhaform.Report_state === "submittedReport"
        ? getSubmittedParentId
        : getReportID;
    _getIjhaform["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _getIjhaform["crew_members"] = [
      EMP_ID().replaceAll('"', ""),
      ...crew_members_id,
    ];
    _getIjhaform["crew_member_selection_type"] = dropdownstatus;
    _getIjhaform["inProgressSection"] = getClimber ? 1 : getProceed;
    _getIjhaform["membertype"] = [getCrewLeadData.membertype, ...membertype];
    _getIjhaform["latitude"] =
      getLoc.lat === ""
        ? getLocationavailable && getLocationavailable.lat !== undefined
          ? getLocationavailable.lat
          : getIjhaform.latitude
        : getLoc.lat;
    _getIjhaform["longitude"] =
      getLoc.lng === ""
        ? getLocationavailable && getLocationavailable.lng !== undefined
          ? getLocationavailable.lng
          : getIjhaform.longitude
        : getLoc.lng;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    _getIjhaform["weather"] = weatherDetailsChange();
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "0";
    _getIjhaform["usergroupId"] = null;
    _getIjhaform["formId"] = getFormData.formId;
    _getIjhaform["crew_member_required"] = getFormData.crew_member_required;
    _getIjhaform["app_type"] = getFormData.app_type;
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";
    _getIjhaform["form_name"] = getFormData.form_name;

    ijhaInCompleteFormSubmit(_getIjhaform, setLoading, setSubmitStatusErr);

    setUpdateWeather(true);
  };

  useEffect(() => {
    if (getCrewMenProceed) {
      const indexsWithCrewMember = form_template
        .map((item, index) => (item.hasOwnProperty("section") ? index : -1))
        .filter((index) => index !== -1);
      if (indexsWithCrewMember[0] === getProceed) {
        setdisabledback(true);
      } else {
        setdisabledback(false);
      }
    }
  }, [getProceed]);

  useEffect(() => {
    let _form_template = _.cloneDeep(form_template);
    _form_template.map((obj, k) => {
      // code for crew-lead or common section fill
      if (getCrewLead === 0) {
        if (getProceed === k) {
          if (!_.isEmpty(obj.section)) {
            if (obj.section[getCrewLead].start_time === "") {
              obj.section[getCrewLead].start_time = _datetime();
            } else {
              obj.section[getCrewLead].start_time = _datetime();
            }
          } else {
            if (obj.start_time === "") {
              obj.start_time = _datetime();
            } else {
              obj.start_time = _datetime();
            }
          }
        } else {
          if (getProceedOld !== "" && getProceedOld === k) {
            if (!_.isEmpty(obj.section)) {
              if (obj.section[getCrewLead].end_time === "" && obj.section[getCrewLead].start_time !== "") {
                obj.section[getCrewLead].end_time = _datetime();
                obj.section[getCrewLead].time_taken = _totalTime(obj.section[getCrewLead].start_time, obj.section[getCrewLead].end_time);
              } else {
                if (obj.section[getCrewLead].start_time !== "") {
                  obj.section[getCrewLead].end_time = _datetime();
                  const oldtime_taken = obj.section[getCrewLead].time_taken;
                  const newtime_taken = _totalTime(obj.section[getCrewLead].start_time, obj.section[getCrewLead].end_time);
                  obj.section[getCrewLead].time_taken = oldtime_taken + newtime_taken;
                }
              }
            } else {
              if (obj.end_time === "" && obj.start_time !== "") {
                obj.end_time = _datetime();
                obj.time_taken = _totalTime(obj.start_time, obj.end_time);
              } else {
                if (obj.start_time !== "") {
                  obj.end_time = _datetime();
                  const oldtime_taken = obj.time_taken;
                  const newtime_taken = _totalTime(obj.start_time, obj.end_time);
                  obj.time_taken = oldtime_taken + newtime_taken;
                }
              }
            }
          }
        }
      }
      // code for crew-member fill
      else {
        if (getProceed === k) {
          if (!_.isEmpty(obj.section)) {
            if (obj.section[getCrewLead].start_time === "") {
              obj.section[getCrewLead].start_time = _datetime();
            } else {
              obj.section[getCrewLead].start_time = _datetime();
            }
          }
        } else {
          if (getProceedOld !== "" && getProceedOld === k) {
            if (!_.isEmpty(obj.section)) {
              if (obj.section[getCrewLead].end_time === "" && obj.section[getCrewLead].start_time !== "") {
                obj.section[getCrewLead].end_time = _datetime();
                obj.section[getCrewLead].time_taken = _totalTime(obj.section[getCrewLead].start_time, obj.section[getCrewLead].end_time);
              } else {
                if (obj.section[getCrewLead].start_time !== "") {
                  obj.section[getCrewLead].end_time = _datetime();
                  const oldtime_taken = obj.section[getCrewLead].time_taken;
                  const newtime_taken = _totalTime(obj.section[getCrewLead].start_time, obj.section[getCrewLead].end_time);
                  obj.section[getCrewLead].time_taken = oldtime_taken + newtime_taken;
                }
              }
            }
          }
        }
      }
    });

    const pageIndexofEnvironmental = form_template.findIndex(
      (item) => item.sectionName === "Environmental & Health Hazards - Weather"
    );
    if (getProceed === pageIndexofEnvironmental) {
      async function fetchData() {
        await getWeatherApi(
          setForm_template,
          _form_template,
          sendData,
          setWeatherHours
        );
      }
      fetchData();
    }
    setForm_template(_form_template);

  }, [getProceedOld, getProceed])

  const proceedNxtSec = () => {
    setProceedOld(getProceed);
    InprocessFormSubmit();
    if (!getCrewMenProceed) {
      if (_selectedSections.length > getProceed) {
        const sectionData = Object.values(form_template).find(
          (section) => section.sectionId === _selectedSections[getProceed + 1]
        );
        if (sectionData && !sectionData.visibility) setProceed(getProceed + 2);
        else {
          setProceed(getProceed + 1);
          _setProceed(getProceed + 1);
        }
      }
    } else {
      const indexsWithCrewMember = form_template
        .map((item, index) => (item.hasOwnProperty("section") ? index : -1))
        .filter((index) => index !== -1);
      if (!indexsWithCrewMember.includes(getProceed)) return;
      const CrewSecIndex = indexsWithCrewMember.findIndex(
        (element) => element === getProceed
      );
      if (CrewSecIndex === -1) return;

      if (CrewSecIndex === indexsWithCrewMember.length - 1) {
        setProceed(form_template.length);
      } else {
        setProceed(indexsWithCrewMember[CrewSecIndex + 1]);
      }
    }
    if (getProceed === form_template.length - 1) {
      let _getIsCompleted = _.cloneDeep(getIsCompleted);
      _getIsCompleted[getCrewLead] = true;
      setIsCompleted(_getIsCompleted);
    }
  };

  const onBackSec = () => {
    setProceedOld(getProceed);
    if (getProceed === 0) {
      setIsAddCrew(false);
      setCrewUpdate(true);
    } else if (getProceed > 0) {
      if (getCrewMenProceed && getProceed < form_template.length) {
        const _findTotalIdx = form_template
          .map((i, k) => "section" in i && k.toString())
          .filter((j) => j);
        const _findIdx = _findTotalIdx.indexOf(getProceed.toString());
        const _Idx = parseInt(_findTotalIdx[_findIdx - 1]);
        setProceed(_Idx);
      } else {
        setProceed(getProceed - 1);
        _setProceed(getProceed - 1);
      }
    }
  };

  const _selectedSection_render = form_template.filter((obj, i) => {
    return (
      _selectedSections.includes(obj.sectionId) &&
      obj.sectionId === _selectedSections[getProceed]
    );
  });

  const addMorePPEOther = () => {
    let _form_template = _.cloneDeep(form_template);

    _form_template
      .find((item) => item.sectionName === "Personal Protective Equipment")
      .section[getCrewLead].questions.push({
        answer: "",
        cameraType: "Front",
        category: "",
        confidence: "",
        message: "Please show the PPE",
        methodType: "Manual",
        multiple_picture: [],
        options: [],
        other_question: true,
        overridden: "",
        picName: "ppe_other",
        picture: "",
        pictureRequired: "Yes",
        question: "PPE - Other",
        questionId: "54",
        questionType: "Image+Text+Other",
        state: "Optional",
        threshold: "",
        validation: {
          errorMessage: "Please upload the picture",
        },
        visibility: true,
      });

    setForm_template(_form_template);
  };

  function updatedFormTemp1() {
    form_template.map((section) => {
      if (section.hasOwnProperty("section")) {
        const updatedSection = {
          ...section,
          section: section.section.map((innerSection) => {
            if (innerSection.hasOwnProperty("questions")) {
              const updatedQuestions = innerSection.questions.map(
                (question) => {
                  if (
                    question.question ===
                    "Please upload an image of Individual Wearing the following PPE - Hard Hat, Work Gloves, Work Boots, Climbing harness"
                  ) {
                    question.visibility = true;
                  }
                  return question;
                }
              );
              return { ...innerSection, questions: updatedQuestions };
            }
            return innerSection;
          }),
        };
        return updatedSection;
      } else if (section.hasOwnProperty("questions")) {
        const updatedQuestions = section.questions.map((question) => {
          if (
            question.question ===
            "Please upload an image of Individual Wearing the following PPE - Hard Hat, Work Gloves, Work Boots, Climbing harness"
          ) {
            return { ...question, visibility: true };
          }
          return question;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    }, []);
  }

  function updatedFormTemp() {
    form_template.map((section) => {
      if (section.hasOwnProperty("section")) {
        const updatedSection = {
          ...section,
          section: section.section.map((innerSection) => {
            if (innerSection.hasOwnProperty("questions")) {
              const updatedQuestions = innerSection.questions.map(
                (question) => {
                  if (
                    question.question ===
                    "Please upload an image of Individual Wearing the following PPE - Hard Hat, Work Gloves, Work Boots, Climbing harness"
                  ) {
                    question.visibility = false;
                  }
                  return question;
                }
              );
              return { ...innerSection, questions: updatedQuestions };
            }
            return innerSection;
          }),
        };
        return updatedSection;
      } else if (section.hasOwnProperty("questions")) {
        const updatedQuestions = section.questions.map((question) => {
          if (
            question.question ===
            "Please upload an image of Individual Wearing the following PPE - Hard Hat, Work Gloves, Work Boots, Climbing harness"
          ) {
            return { ...question, visibility: false };
          }
          return question;
        });
        return { ...section, questions: updatedQuestions };
      }
      return section;
    }, []);
  }

  const onSORFormSubmit = () => {
    setLoading1(true);
    let crew_members_id = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.employeeId)
      : "";
    let dropdownstatus = getCrewLeadData.status;
    // uncommented below to active climber and nonclimber
    let membertype = getCrewLeadData.crewmemberdata
      ? getCrewLeadData.crewmemberdata.map((obj) => obj.membertype)
      : "";
    let _getIjhaform = _.cloneDeep(getIjhaform);
    updatedFormTemp();
    _getIjhaform["form_template"] = formTempChange();
    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["parent_reportId"] =
      _getIjhaform.hasOwnProperty("Report_state") &&
        getIjhaform.Report_state === "submittedReport"
        ? getSubmittedParentId
        : getReportID;
    _getIjhaform["Report_state"] = getIjhaform.Report_state;
    _getIjhaform["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _getIjhaform["crew_members"] = [
      EMP_ID().replaceAll('"', ""),
      ...crew_members_id,
    ];
    _getIjhaform["crew_member_selection_type"] = dropdownstatus;
    // uncommented below to active climber and nonclimber
    _getIjhaform["membertype"] = [getCrewLeadData.membertype, ...membertype];
    _getIjhaform["latitude"] =
      getLoc.lat === ""
        ? getLocationavailable && getLocationavailable.lat !== undefined
          ? getLocationavailable.lat
          : getIjhaform.latitude
        : getLoc.lat;
    _getIjhaform["longitude"] =
      getLoc.lng === ""
        ? getLocationavailable && getLocationavailable.lng !== undefined
          ? getLocationavailable.lng
          : getIjhaform.longitude
        : getLoc.lng;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    _getIjhaform["weather"] = weatherDetailsChange();
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "1";
    _getIjhaform["usergroupId"] = null;

    _getIjhaform["formId"] = getFormData.formId;
    _getIjhaform["crew_member_required"] = getFormData.crew_member_required;
    _getIjhaform["app_type"] = getFormData.app_type;
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["total_time_taken"] = getTotalTime(
      _getIjhaform["start_time_datetime"],
      _getIjhaform["submitted_datetime"]
    );
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";
    _getIjhaform["form_name"] = getFormData.form_name;
    ijhaFormSubmit(_getIjhaform, setLoading, setSubmitMsg);
    setIjhaform(_getIjhaform);
  };

  function getSecondPart(str) {
    return str.split("-")[1];
  }
  function getFirstPart(str) {
    return str.split("-")[0];
  }
  const provideLatLngfrmStr = (text) =>
    text
      .split("(")[1]
      .split(")")
      .filter((obj) => obj !== "")[0]
      .split(",")
      .map((obj) => Number(obj));

  const changeFormTemplateDataforSpecificSection = (args) => {
    const [_type, _value, _key, _s_key, _key_name, _attri] = [...args];
    let first_part, second_part, latitude, longitude;
    let _form_template = _.cloneDeep(form_template);
    switch (_type) {
      case 1:
        const indextoEditformofValue = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _key);
        _form_template[getProceed].questions[indextoEditformofValue].answer =
          _value;
        break;

      case 28:
        const indextoEditformSection = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditform = _form_template[
          indextoEditformSection
        ].questions.findIndex((question) => question.questionId === _key);

        first_part = _value;
        if (_value === "Other") {
          setCustomername1(true);
          second_part = "";

          _form_template[indextoEditformSection].questions[
            indextoEditform
          ].answer = first_part.concat("-", second_part);

          break;
        } else {
          setCustomername1(false);

          second_part = getSecondPart(
            _form_template[indextoEditformSection].questions[indextoEditform]
              .answer
          );
          _form_template[indextoEditformSection].questions[
            indextoEditform
          ].answer = first_part;
        }
        break;

      case 29:
        const indextoEditformSectionOther = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditform1 = _form_template[
          indextoEditformSectionOther
        ].questions.findIndex((question) => question.questionId === _key);

        first_part = getFirstPart(
          _form_template[indextoEditformSectionOther].questions[
            indextoEditform1
          ].answer
        );
        second_part = _value;
        _form_template[indextoEditformSectionOther].questions[
          indextoEditform1
        ].answer = first_part.concat("-", second_part);

        break;

      case 30:
        const indextoEditformSectionSite = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditformsiteLand = _form_template[
          indextoEditformSectionSite
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = _value;
        if (_value === "Other") {
          setSiteLandLord(true);
          second_part = "";
          _form_template[indextoEditformSectionSite].questions[
            indextoEditformsiteLand
          ].answer = first_part.concat(" - ", second_part);
        } else {
          setSiteLandLord(false);

          second_part = getSecondPart(
            _form_template[indextoEditformSectionSite].questions[
              indextoEditformsiteLand
            ].answer
          );
          _form_template[indextoEditformSectionSite].questions[
            indextoEditformsiteLand
          ].answer = first_part.concat("-", second_part);
        }
        break;

      case 31:
        const indextoEditformSectionSiteOther = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditformsiteLandOther = _form_template[
          indextoEditformSectionSiteOther
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = getFirstPart(
          _form_template[indextoEditformSectionSiteOther].questions[
            indextoEditformsiteLandOther
          ].answer
        );
        second_part = _value;
        _form_template[indextoEditformSectionSiteOther].questions[
          indextoEditformsiteLandOther
        ].answer = first_part.concat("-", second_part);
        break;

      case 26:
        const indexToEditforFireextin = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _s_key);

        if (
          _form_template[getProceed].questions[indexToEditforFireextin]
            .methodType === "AI" &&
          _form_template[getProceed].questions[indexToEditforFireextin]
            .confidence !== "" &&
          _form_template[getProceed].questions[indexToEditforFireextin]
            .answer === "No"
        ) {
          _form_template[getProceed].questions[
            indexToEditforFireextin
          ].overridden = _value;
        } else {
          _form_template[getProceed].questions[
            indexToEditforFireextin
          ].overridden = _value;
        }
        break;

      case 15:
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );

        modalMapHandler(
          _value,
          getAddress,
          changeFormTemplateDataforSpecificSection,
          form_template[getProceed].questions[_key].lat,
          form_template[getProceed].questions[_key].long,
          _key
        );

        break;
      case 7:
        const indexToEdit12 = _form_template[getProceed].section[
          getCrewLead
        ].questions.findIndex((question) => question.questionId === _s_key);

        if (
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].methodType === "AI" &&
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].confidence !== "" &&
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].answer === "No"
        ) {
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].overridden = _value;
        } else {
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].overridden = _value;
        }
        break;

      case 13:
        if (typeof _value === "object") {
          const questionstoUpdate =
            _form_template[getProceed].questions ||
            _form_template[getProceed].section[getCrewLead].questions;

          const indexToEdit = questionstoUpdate.findIndex(
            (question) => question.questionId === _s_key
          );

          questionstoUpdate[indexToEdit] = {
            ...questionstoUpdate[indexToEdit],
            picture: _value[0],
            answer: _value[1],
            confidence: _value[2],
            threshold: _value[3],
            ai_approved: _value[5],
            image_metadata: getImgMetaData,
            CheckSumValueOfImage: getChecksumValue,
            checkSumVerified: getVerifiedValue,
          };
        }

        break;
      case 25:
        if (typeof _value === "object") {
          const indexToEdit = _form_template[getProceed].questions.findIndex(
            (question) => question.questionId === _s_key
          );
          _form_template[getProceed].questions[indexToEdit] = {
            ..._form_template[getProceed].questions[indexToEdit],
            picture: _value[0],
            answer: _value[1],
            confidence: _value[2],
            threshold: _value[3],
            ai_approved: _value[5],
          };
        }
        break;

      case 32:
        const questionstoUpdate =
          _form_template[getProceed].questions ||
          _form_template[getProceed].section[getCrewLead].questions;

        const indexToEdit = questionstoUpdate.findIndex(
          (question) => question.questionId === _s_key
        );
        deleteImageFromBlob(questionstoUpdate[indexToEdit].picture);
        // questionstoUpdate[indexToEdit] = {
        //   ...questionstoUpdate[indexToEdit],
        //   picture: "",
        //   image_metadata: "",
        //   confidence: "",
        //   answer: "No",
        //   CheckSumValueOfImage: "",
        //   checkSumVerified: "",
        //   ai_approved: "",
        // };
        const picName = questionstoUpdate[indexToEdit].picture;
        questionstoUpdate.map((i, k) => {
          if (i.hasOwnProperty("CheckSumValueOfImage") && i.picture === picName) {
            questionstoUpdate[k] = {
              ...i,
              picture: "",
              image_metadata: "",
              confidence: "",
              answer: "No",
              CheckSumValueOfImage: "",
              checkSumVerified: "",
              ai_approved: "",
              threshold: ""
            }
          }
        });

        break;
      case 38:
        deleteImageFromBlob(_form_template[getProceed].questions[0].picture);
        if (_form_template[getProceed].questions[0].picture !== "") {
          _form_template[getProceed].questions[0].picture = "";
          _form_template[getProceed].questions[0].image_metadata = "";
          _form_template[getProceed].questions[0].confidence = "";
          _form_template[getProceed].questions[0].answer = "No";
          _form_template[getProceed].questions[0].ai_approved = "";
          _form_template[getProceed].questions[0].CheckSumValueOfImage = "";
          _form_template[getProceed].questions[0].checkSumVerified = "";
        }
        break;
      case 4:
        let data_loc = "";
        if (_form_template[getProceed].questions[_key].answer !== "") {
          data_loc =
            _form_template[getProceed].questions[_key].answer.split("(")[0];
        }

        latitude =
          getLatitude === 0
            ? getLocationavailable && getLocationavailable.lat !== undefined
              ? getLocationavailable.lat
              : getLoc.lat !== ""
                ? getLoc.lat
                : getIjhaform.latitude
            : getLatitude;

        longitude =
          getLongitude === 0
            ? getLocationavailable && getLocationavailable.lng !== undefined
              ? getLocationavailable.lng
              : getLoc.lng !== ""
                ? getLoc.lng
                : getIjhaform.longitude
            : getLongitude;
        if (typeof _value === "object") {
          _form_template[getProceed].questions[_key].answer = _value[0];
          _form_template[getProceed].questions[_key].distance = _value[1];
          _form_template[getProceed].questions[_key].duration = _value[2];
        } else {
          _form_template[getProceed].questions[_key].answer = _value;
        }
        let latLog = provideLatLngfrmStr(
          _form_template[getProceed].questions[_key].answer
        );
        _form_template[getProceed].questions[_key].lat = latLog[0];
        _form_template[getProceed].questions[_key].long = latLog[1];
        break;
      case 16:
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        modalMapDirectionHandler(
          _value,
          getAddress,
          changeFormTemplateDataforSpecificSection,
          _key
        );
        break;

      case 20:
        const indexToEditforSubquestion = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _s_key);

        form_template[getProceed].questions[
          indexToEditforSubquestion
        ].subQuestion[0][_key_name] = _value;
        _form_template[getProceed].questions[
          indexToEditforSubquestion
        ].subQuestion[0]["fileType"] = getFileTypeImgDoc(_value);
        break;
      case 36:
        _form_template[getProceed].questions[_key].options[_s_key] = _value;
        _form_template[getProceed + 1].questions[0].multipleQuestion[
          _s_key
        ].questions[0].description = _value;

        break;
      case 55:
        const pageIndex = form_template.findIndex(
          (item) => item.sectionId === _key_name
        );

        _form_template[pageIndex].section[getCrewLead].questions[_key].options[
          _s_key
        ] = _value;

        break;

      case 35:
        _form_template[getProceed].questions[0].answer = _value;
        const indextoEditfortypeofstructure12 = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _key);
        _form_template.map((obj, i) => {
          (obj.section && obj.section[getCrewLead]
            ? obj.section[getCrewLead]
            : obj
          ).questions.map((s_obj, k) => {
            first_part = _value;
            if (_value === "Other") {
              settypeofstructure(true);
              second_part = "";

              _form_template[getProceed].questions[
                indextoEditfortypeofstructure12
              ].answer = first_part.concat("-", second_part);
            } else {
              settypeofstructure(false);

              second_part = getSecondPart(
                _form_template[getProceed].questions[
                  indextoEditfortypeofstructure12
                ].answer
              );
              _form_template[getProceed].questions[
                indextoEditfortypeofstructure12
              ].answer = first_part;
            }

            if (s_obj.typeOfWork && !s_obj.typeOfWork.includes(_value)) {
              s_obj.visibility = false;
            } else {
              s_obj.visibility = true;
            }
          });
        });
        _form_template = validateClimber(_form_template);
        break;

      case 24:
        if ("section" in _form_template[getProceed]) {
          _form_template[getProceed].section[_key].questions[_key_name].checkbox_model[0].value = _value;
        } else {
          _form_template[getProceed].questions[_key_name].checkbox_model[0].value = _value;
        }
        break;

      case 44:
        const indextoEditfortypeofstructure1 = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = getFirstPart(
          _form_template[getProceed].questions[indextoEditfortypeofstructure1]
            .answer
        );
        second_part = _value;

        _form_template[getProceed].questions[
          indextoEditfortypeofstructure1
        ].answer = first_part.concat("-", second_part);

        break;

      case 45:
        const indextoEditEPA = _form_template[getProceed].questions.findIndex(
          (question) => question.questionId === _key
        );
        first_part = getFirstPart(
          _form_template[getProceed].questions[indextoEditEPA].subQuestion[1]
            .answer
        );
        second_part = _value;
        _form_template[getProceed].questions[
          indextoEditEPA
        ].subQuestion[1].answer = first_part.concat("-", second_part);
        break;

      default:
        break;
    }

    setForm_template(_form_template);
    // deleteImageFromBlob(args.reportId);
  };

  const onChangeFormTemplate = (changeValArr) => {
    let _form_template = _.cloneDeep(form_template);
    let first_part, second_part;
    _form_template.map((obj) => {
      if (obj.sectionId === changeValArr.sectionId) {
        return (
          obj.section && obj.section[getCrewLead]
            ? obj.section[getCrewLead]
            : obj
        ).questions.map((s_obj) => {
          if (s_obj.questionId === changeValArr.questionId) {
            if (changeValArr.subQuestion)
              s_obj.subQuestion[changeValArr.subQuestionNo].answer =
                changeValArr.answer;
            else if (changeValArr.answer !== undefined) {
              if (s_obj.hasOwnProperty("impactedSectionQuestions_Height")) {
                const selectedAnswer = changeValArr.answer;
                const impactedSectionQuestions =
                  s_obj.impactedSectionQuestions_Height;
                impactedSectionQuestions.forEach((impactedSectionQuestion) => {
                  if (selectedAnswer === impactedSectionQuestion.answer) {
                    const currentSectionId = impactedSectionQuestion.sectionId;
                    const questionIds = impactedSectionQuestion.questions.map(
                      (id) => id.toString()
                    );
                    _form_template.forEach((sectionObj) => {
                      if (sectionObj.sectionId === currentSectionId) {
                        if (sectionObj.questions) {
                          sectionObj.questions.forEach((question) => {
                            question.state =
                              question.defaultState || "Optional";
                            if (
                              questionIds.includes(
                                question.questionId.toString()
                              )
                            ) {
                              switch (impactedSectionQuestion.action) {
                                case 0:
                                  question.visibility = false;
                                  break;
                                case 1:
                                  question.visibility = true;
                                  break;
                                case 2:
                                  question.state = "Required";
                                  break;
                                case 3:
                                  question.state = "Optional";
                                  break;
                                case 4:
                                  question.answer = selectedAnswer;
                                  break;
                                default:
                              }
                            }
                          });
                        } else if (sectionObj.section) {
                          sectionObj.section.forEach((subsection) => {
                            subsection.questions.forEach((question) => {
                              question.state =
                                question.defaultState || "Optional";
                              if (
                                questionIds.includes(
                                  question.questionId.toString()
                                )
                              ) {
                                switch (impactedSectionQuestion.action) {
                                  case 0:
                                    question.visibility = false;
                                    break;
                                  case 1:
                                    question.visibility = true;
                                    break;
                                  case 2:
                                    question.state = "Required";
                                    break;
                                  case 3:
                                    question.state = "Optional";
                                    break;
                                  case 4:
                                    question.answer = selectedAnswer;
                                    break;
                                  default:
                                }
                              }
                            });
                          });
                        }
                      }
                    });
                  }
                });
                s_obj.answer = selectedAnswer;
              } else {
                s_obj.answer = changeValArr.answer;
              }
            } else if (changeValArr.signature !== undefined) {
              s_obj.signature = changeValArr.signature;
            } else if (changeValArr.desc_text !== undefined)
              s_obj.description_model[0].text = changeValArr.desc_text;
            else if (changeValArr.picture !== undefined)
              s_obj.picture = changeValArr.picture;
            else if (changeValArr.multiple_picture !== undefined)
              s_obj.multiple_picture.push(changeValArr.multiple_picture);
            else if ("Dropdown_answer" in changeValArr) {
              if (s_obj.hasOwnProperty("jobType")) {
                const selectedAnswer = changeValArr.Dropdown_answer;
                const jobTypeAnswers = s_obj.jobType;
                jobTypeAnswers.forEach((jobTypeAnswer) => {
                  const currentSectionId = jobTypeAnswer.sectionId;
                  const jobTypeMappings = jobTypeAnswer.answers;
                  _form_template.forEach((sectionObj) => {
                    if (sectionObj.sectionId === currentSectionId) {
                      if (sectionObj.questions) {
                        sectionObj.questions.forEach((question) => {
                          question.state = question.defaultState || "Optional";
                        });
                        sectionObj.questions.forEach((question) => {
                          const questionId = question.questionId.toString();
                          let action = 1;
                          jobTypeMappings.forEach((jobTypeObj) => {
                            const jobTypeAnswer = jobTypeObj.answer;
                            const actionNumber = jobTypeObj.action;
                            const impactedQuestions =
                              jobTypeObj.impactedQuestions.map((id) =>
                                id.toString()
                              );
                            if (
                              selectedAnswer === jobTypeAnswer &&
                              impactedQuestions.includes(questionId)
                            ) {
                              action = actionNumber;
                            }
                          });
                          switch (action) {
                            case 0:
                              question.visibility = false;
                              break;
                            case 1:
                              question.visibility = true;
                              break;
                            case 2:
                              question.state = "Required";
                              break;
                            case 3:
                              question.state = "Optional";
                              break;
                            case 4:
                              question.answer = selectedAnswer;
                              break;
                            default:
                          }
                        });
                      } else if (sectionObj.section) {
                        sectionObj.section.forEach((subsection) => {
                          subsection.questions.forEach((question) => {
                            question.state =
                              question.defaultState || "Optional";
                          });
                        });
                        sectionObj.section.forEach((subsection) => {
                          subsection.questions.forEach((question) => {
                            const questionId = question.questionId.toString();
                            let action = 1;
                            jobTypeMappings.forEach((jobTypeObj) => {
                              const jobTypeAnswer = jobTypeObj.answer;
                              const actionNumber = jobTypeObj.action;
                              const impactedQuestions =
                                jobTypeObj.impactedQuestions.map((id) =>
                                  id.toString()
                                );
                              if (
                                selectedAnswer === jobTypeAnswer &&
                                impactedQuestions.includes(questionId)
                              ) {
                                action = actionNumber;
                              }
                            });
                            switch (action) {
                              case 0:
                                question.visibility = false;
                                break;
                              case 1:
                                question.visibility = true;
                                break;
                              case 2:
                                question.state = "Required";
                                break;
                              case 3:
                                question.state = "Optional";
                                break;
                              case 4:
                                question.answer = selectedAnswer;
                                break;
                              default:
                            }
                          });
                        });
                      }
                    }
                  });
                });
                s_obj.answer = selectedAnswer;
              } else {
                s_obj.answer = changeValArr.Dropdown_answer;
                first_part = changeValArr.Dropdown_answer;
                if (changeValArr.Dropdown_answer === "Other") {
                  second_part = "";
                  s_obj.answer = first_part.concat("-", second_part);
                } else {
                }
              }
            } else if ("OtherOption" in changeValArr) {
              second_part = changeValArr.OtherOption;
              first_part = getFirstPart(s_obj.answer);
              s_obj.answer = first_part.concat("-", second_part);
            }
          }
          return s_obj;
        });
      }
      return obj;
    });

    if (changeValArr.questionId === "9") {
      _form_template = validateClimber(_form_template);
    }
    setForm_template(_form_template);
  };

  const validateClimber = (_form_template) => {
    setLoading(true);
    setClimber(false);
    let climberActive = false;
    let typeWork;
    const _Climber_count = getClimberList.filter((x) => x === "Climber").length;
    _form_template.map((obj) => {
      if (obj.sectionName === "Type of Work") {
        obj.questions.map((s_obj) => {
          if (s_obj.question === "Type of Structure") {
            typeWork = s_obj.answer.split("-")[0];
          }

          if (
            s_obj.questionId === "9" &&
            s_obj.answer === "Yes" &&
            _Climber_count <= 1 &&
            s_obj.visibility
          ) {
            climberActive = true;
            setClimber(true);
          } else {
            if (climberActive) {
              s_obj.visibility = false;
            } else {
              if (
                "typeOfWork" in s_obj &&
                s_obj.typeOfWork.includes(typeWork)
              ) {
                s_obj.visibility = true;
              }
            }
          }
        });
      }
    });
    setLoading(false);
    return _form_template;
  };

  useEffect(() => {
    if (!_.isEmpty(getClimberList)) {
      let _form_template = _.cloneDeep(form_template);
      _form_template = validateClimber(_form_template);
      setForm_template(_form_template);
    }
  }, [getClimberList]);

  const checkFieldValidation = () => {
    let isValid = true;

    if (getProceed !== form_template.length) {
      const sectionData = form_template.find(
        (item) => item.sectionId === _selectedSections[getProceed]
      );
      (sectionData.section && sectionData.section[getCrewLead]
        ? sectionData.section[getCrewLead]
        : sectionData
      ).questions.forEach((question) => {
        if (
          (sectionData.sectionId === "15" && getClimber) ||
          (sectionData.sectionId === "6" && getError2) ||
          (question.state === "Required" &&
            question.visibility === true &&
            (question.questionType === "Radio+Image"
              ? question.picture === ""
              : question.questionType === "Radio+Signature"
                ? !question.signature
                : question.questionType === "Description+Dropdown+Rearrange"
                  ? question.options.some((value) => !value)
                  : question.questionType === "Radio+Signature"
                    ? !question.signature
                    : question.questionType === "Multiple-questions"
                      ? question.multipleQuestion.some((value) => !value)
                      : question.questionType === "Radio+Signature"
                        ? !question.signature
                        : question.questionType === "Description+Rearrange"
                          ? question.options.some((value) => !value)
                          : question.questionType === "Radio+Signature"
                            ? !question.signature
                            : question.questionType === "Image"
                              ? question.picture || _.isEmpty(question.multiple_picture)
                              : question.questionType === "Radio+Signature"
                                ? !question.signature
                                : question.questionType === "Radio+Signature"
                                  ? !question.signature
                                  : question.questionType === "Dropdown+Text"
                                    ? question.answer && question.answer.split("-")[0] === "Other"
                                      ? !question.answer.split("-")[1]
                                      : !question.answer
                                    : question.questionType === "Sub-question"
                                      ? question.subQuestion[1].answer &&
                                        question.subQuestion[1].answer.split("-")[0] === "Other"
                                        ? !question.subQuestion[1].answer.split("-")[1]
                                        : !question.subQuestion[1].answer
                                      : question.questionType === "Radio+Signature"
                                        ? !question.signature
                                        : question.questionType === "Dropdown"
                                          ? question.answer && question.answer.split("-")[0] === "Other"
                                            ? !question.answer.split("-")[1]
                                            : !question.answer
                                          : question.questionType === "Radio+Signature"
                                            ? !question.signature
                                            : question.questionType === "Signature"
                                              ? !question.signature
                                              : question.questionType === "Terms&condition"
                                                ? !question.checkbox_model[0].value
                                                : !question.answer.trim()))
        ) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  const _datetime = () => {
    let _date = new Date();
    let current_date =
      _date.getFullYear() +
      "/" +
      (_date.getMonth() + 1) +
      "/" +
      _date.getDate() +
      " " +
      +_date.getHours() +
      ":" +
      _date.getMinutes() +
      ":" +
      _date.getSeconds();
    return current_date;
  };

  const _totalTime = (_start_time, _end_time) => {
    const _total_Time =
      Math.abs(
        new Date(_end_time).getTime() - new Date(_start_time).getTime()
      ) / 1000;

    return _total_Time;
  };

  console.log("WebApp", form_template);

  return (
    <Row>
      <h4>{getFormData.form_name}</h4>
      <Row>
        <Col>
          <FormProgressBarforDynamic
            getClimber={getClimber}
            inProgressSection={
              getIjhaform.inProgressSection !== "" &&
                getIjhaform.inProgressSection !== undefined
                ? true
                : false
            }
            getIsCompleted={getIsCompleted[getCrewLead]}
            getProgressCount={getProceed1 === 0 ? getProceed + 1 : 15}
            getCrewLead={getCrewLead}
            stepSection={getProceed}
            _selectedSectionJson={form_template.filter((obj, i) =>
              _selectedSections.includes(obj.sectionId)
            )}
            getsummary={_selectedSections.length === getProceed}
            setProceed={setProceed}
            form_template={form_template}
            getReportRequired={getReportRequired}
            getCrewfill={getCrewfill}
            getCrewfillComplete={getCrewfillComplete}
            setCrewfillComplete={setCrewfillComplete}
            getProceed={getProceed}
            setProceedOld={setProceedOld}
          />
        </Col>
        {
          <div className="mb-2 d-flex justify-content-end">
            <div
              className="p-2 m-1 ps-3 pe-3 rounded-pill text-capitalize"
              style={{ background: "rgb(33 37 41)", color: "#f1f1f1" }}
            >
              {getCrewLead !== 0
                ? "Crew Member: " +
                getCrewLeadData.crewmemberdata[getCrewLead - 1].firstName +
                " " +
                getCrewLeadData.crewmemberdata[getCrewLead - 1].lastName
                : "Crew Lead: " +
                getCrewLeadData.firstName +
                " " +
                getCrewLeadData.lastName}
            </div>
          </div>
        }

        {showMdl ? (
          <ModalMapCustomforIjhaDynamic
            showMdl={showMdl}
            setShowMdl={setShowMdl}
            dataMdl={getDataMdl}
            getAddress={getAddress}
            setAddress={setAddress}
            setDirections={setDirections}
            directions={directions}
            form_template={form_template}
            setForm_template={setForm_template}
          />
        ) : (
          <ModalMapTramaCenterforIJhaDynamic
            showMdl={showTraumaMdl}
            setShowMdl={setShowTraumaMdl}
            dataMdl={getDataMdl}
            getAddress={getAddress}
            setAddress={setAddress}
            form_template={form_template}
            setForm_template={setForm_template}
          />
        )}
      </Row>
      <Row className="shadow-lg p-3 mb-5 mt-3 rounded ">
        <>
          {_selectedSection_render.map((obj, i) => {
            return (
              <Row key={i}>
                <Row className="mb-3">
                  <Col lg="12">
                    <div className="form-subheading-1">{obj.sectionName}</div>
                  </Col>
                </Row>
                <Row>
                  {(obj.section && obj.section[getCrewLead]
                    ? obj.section[getCrewLead]
                    : obj
                  ).questions.map((s_obj, k) => {
                    return (
                      <Row xs={12} key={k}>
                        <Col className="sect">
                          <SectionCompForIJHA
                            getReportID={getReportID}
                            setImgMetaData={setImgMetaData}
                            getImgMetaData={getImgMetaData}
                            clearErrors={clearErrors}
                            setErrMsg={setErrMsg}
                            typeofstructure={typeofstructure}
                            questions_obj={s_obj}
                            sectionId={obj.sectionId}
                            entiresection={obj}
                            setpbtn={setpbtn}
                            getpbtn={getpbtn}
                            NewError={NewError}
                            getLongitude={getLongitude}
                            getLatitude={getLatitude}
                            onChangeFormTemplate={onChangeFormTemplate}
                            setLoading={setLoading}
                            getLoading={getLoading}
                            changeFormTemplateDataforSpecificSection={
                              changeFormTemplateDataforSpecificSection
                            }
                            sectionName={obj.sectionName}
                            setbutton_control={setbutton_control}
                            getbutton_control={getbutton_control}
                            sendData={sendData}
                            weatherDetailsChange={weatherDetailsChange}
                            addMorePPEOther={addMorePPEOther}
                            wheather_radio={wheather_radio}
                            radioValue={radioValue}
                            getUnit={getUnit}
                            getProceed={getProceed}
                            getChecksumValue={getChecksumValue}
                            setChecksumValue={setChecksumValue}
                            getWeatherHours={getWeatherHours}
                            setWeatherHours={setWeatherHours}
                            getCrewLeadData={getCrewLeadData}
                            setUpdateWeather={setUpdateWeather}
                            getCustomername1={getCustomername1}
                            setCustomername1={setCustomername1}
                            form_template={form_template}
                            setForm_template={setForm_template}
                            setRadioValue={setRadioValue}
                            getSiteLandLord={getSiteLandLord}
                            modalMapHandler={modalMapHandler}
                            modalMapHandler1={modalMapHandler1}
                            removeImgData={removeImgData}
                            getError2={getError2}
                            setError2={setError2}
                            errors={errors}
                            register={register}
                            handleSubmit={handleSubmit}
                            setError={setError}
                            getIjhaform={getIjhaform}
                            modalMapDirectionHandler={modalMapDirectionHandler}
                            setAddress={setAddress}
                            getAddress={getAddress}
                            setvalidationforfields={setvalidationforfields}
                            changeFormTemplateData={changeFormTemplateData}
                            setCertifyData={setCertifyData}
                            getVerifiedValue={getVerifiedValue}
                            setVerifiedValue={setVerifiedValue}
                            getCrewLead={getCrewLead}
                            getCertifyData={getCertifyData}
                            setCertifySection={setCertifySection}
                            questions_obj_key={k}
                            getClimberList={getClimberList}
                          />
                        </Col>
                      </Row>
                    );
                  })}

                  {getClimber && getProceed === 1 && (
                    <Form.Label className="mt-2 text-danger mb-3">
                      You can not proceed because a minimum of 2 climbers are
                      required for any height related work.{" "}
                      <span
                        className="updateCrew"
                        onClick={() => {
                          setIsAddCrew(false);
                          setCrewUpdate(true);
                        }}
                      >
                        Click here to update crew member list.
                      </span>
                    </Form.Label>
                  )}
                  {getError2 !== "" &&
                    getProceed ===
                    form_template.findIndex(
                      (section) => section.sectionId === "6"
                    ) && (
                      <Form.Label className="mt-2 text-danger">
                        {getError2}
                      </Form.Label>
                    )}
                </Row>
              </Row>
            );
          })}
        </>

        {
          <Row>
            {(_.isEmpty(getIjhaform.reportId) ||
              (!_.isEmpty(getIjhaform.reportId) &&
                (!getIsCompleted.every((obj) => obj === true) ||
                  getIsCompleted.every((obj) => obj === true)) &&
                !getLoading1)) &&
              _selectedSections.length === getProceed &&
              !getSubmitMsg && (
                <Row lg="12">
                  <Col>
                    <div className="form-subheading-1">Summary</div>
                    <br />
                    <SummaryForIJHA
                      form_template={form_template.filter((obj, i) =>
                        _selectedSections.includes(obj.sectionId)
                      )}
                      setForm_template={setForm_template}
                      setProceed1={setProceed1}
                      getCrewLeadData={getCrewLeadData}
                      setCrewfill={setCrewfill}
                      setCrewfillComplete={setCrewfillComplete}
                      setIsCompleted={setIsCompleted}
                      setCrewLeadData={setCrewLeadData}
                      setvalidationofsubmit={setvalidationofsubmit}
                      setSummarybutton={setSummarybutton}
                      getIsCompleted={getIsCompleted}
                      setProceed={setProceed}
                      setCrewMenProceed={setCrewMenProceed}
                      setCrewLead={setCrewLead}
                      getCrewLead={getCrewLead}
                    />
                  </Col>
                </Row>
              )}
            {getSubmitMsg === "" && getLoading1 && (
              <div>
                <Loading width="32" height="32" />
                <h5 className="mt-3 d-flex justify-content-center text-center">
                  Please wait while we process your {getFormData.form_name}{" "}
                  submission.
                </h5>
                <h5 className="mt-1 p-2 d-flex justify-content-center text-center">
                  Do not refresh the page or navigate away while this is
                  happening.
                </h5>
              </div>
            )}

            {getSubmitMsg !== "" && (
              <div>
                <Row xs={12}>
                  <Col className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative">
                    <img
                      src={String(ic_complete_ppe)}
                      className="p-2 m-2"
                      alt="check-success"
                      style={{ width: "74px" }}
                    />
                    <h5 className="p-2 bd-highlight">
                      {getSubmitMsg !== "" && getSubmitMsg}
                      {` Your ${getFormData.form_name} will be available in `}

                      {getSubmitMsg !== "" && (
                        <a href="/reports" style={{ fontSize: "1.25rem" }}>
                          Reports tab
                        </a>
                      )}
                    </h5>
                  </Col>
                </Row>
                <Row xs={12}>
                  <Col className="d-flex justify-content-center mt-3 mb-3">
                    Click to go&nbsp;<a href="/">Home Page</a>
                  </Col>
                </Row>
              </div>
            )}

            {!getLoading1 && (
              <Col lg="6">
                <Button
                  onClick={onBackSec}
                  disabled={
                    getLoading ||
                    (getCrewMenProceed && getProceed === 0) ||
                    disabledback
                  }
                >
                  Back
                </Button>
                {form_template.length !== getProceed ? (
                  <Button
                    className="ms-3"
                    variant="success"
                    disabled={!checkFieldValidation() || getLoading}
                    onClick={proceedNxtSec}
                  >
                    Proceed
                  </Button>
                ) : (
                  <div className="d-inline-block ms-3">
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Please make sure all sections are reviewed for all
                          members in the {getFormData.form_name}
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block">
                        <Button
                          variant={"success"}
                          onClick={onSORFormSubmit}
                          disabled={
                            getLoading ||
                            (!getIsCompleted.every((obj) => obj === true) &&
                              validationofsubmit)
                          }
                        >
                          {" "}
                          Submit
                        </Button>
                      </span>
                    </OverlayTrigger>
                  </div>
                )}
                {getIsCompleted.every((obj) => obj === true) &&
                  form_template.length !== getProceed && (
                    <div className="d-inline-block ms-3">
                      <Button
                        className="ms-5 btn btn-dark"
                        onClick={() => {
                          setProceed(selectedSectionData.length);
                        }}
                        variant={"success"}
                        disabled={!checkFieldValidation() || getLoading}
                      >
                        Go to Summary
                      </Button>
                    </div>
                  )}
              </Col>
            )}
          </Row>
        }
        {getLoading && (
          <Row>
            <Col>
              <Loading width="32" height="32" />
            </Col>
          </Row>
        )}
      </Row>
      {!_.isEmpty(getSubmitStatusErr) && (
        <Modal show="modal fade show" onHide={() => { setSubmitStatusErr(""); navigate('/home') }}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {getSubmitStatusErr}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary btn-danger" onClick={() => { setSubmitStatusErr(""); navigate('/home') }}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Row>
  );
}
